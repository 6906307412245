import React from 'react'
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";

import BlockContent from '@sanity/block-content-to-react';

import { device } from '../styles/Breakpoints.js';

// import cornerIconQuery from '../queries/cornerIconQuery'

import styled from 'styled-components';

import Header from '../components/Header';
import CornerIcons from '../components/CornerIcons';
import Cursor from '../components/Cursor.js';





const AboutUsStyles = styled.div`
      main {
        padding-top: var(--header-height--desktop);
        padding-left: 14%;
        padding-right: 14%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media ${device.$medium} {
          padding-top: 128px;
          padding-bottom: 128px;
        }
      }
      h1 {
        margin-bottom: 48px;
        line-height: normal;
        /* line-height: 1em; */
        @media ${device.$medium} {
          text-align: center;
          width: 100%;
        }
      }
      .content {
        width: 100%;
        line-height: 21px;
        .paragraph {
          width: 50%;
          @media ${device.$medium} {
            width: 100%;
          }
          &--01 {
            @media ${device.$medium} {
              padding-bottom: 48px;
            }
          }
          &--02 {
            margin-left: auto;
          }
        }
      }
`

export default function AboutUsPage({ data }) {
    // console.log('DATA', data)

    const cornerIcondata = data.cornerIconData.nodes[0];
    const pageData = data.aboutUsPageData.nodes[0];
    const siteSettings = data.siteSettings.nodes[0];



    return (
      <>
        <Helmet title="About Us | Mimi Casting"/>
        <AboutUsStyles>
          <Header data={siteSettings} />
          <CornerIcons data={cornerIcondata} siteSettings={siteSettings}/>
          <Cursor 
            staticText={siteSettings.cursor_static}
            staticTextColor={siteSettings.cursor_static_color.hex}
            cursorSparkle={siteSettings.cursor_sparkle}
          />
            <main>
              <h1>{pageData.page_title}</h1>
              <div className="content">
                <BlockContent 
                  className="paragraph paragraph--01"
                  blocks={pageData._rawParagraph01} 
                  renderContainerOnSingleChild={true}
                />
                <BlockContent 
                  className="paragraph paragraph--02"
                  blocks={pageData._rawParagraph02}
                  renderContainerOnSingleChild={true}
                />
              </div>
            </main>
        </AboutUsStyles>
      </>
    )
}

export const query = graphql`
query {
    cornerIconData: allSanityCornerIcons {
        nodes {
          corner_icon__top_left_url
          corner_icon__top_right_url
          corner_icon__bottom_left_url
          corner_icon__bottom_right_url
          corner_icon__top_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__top_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
        }
    }
    aboutUsPageData: allSanityAboutUsPage {
      nodes {
        _rawParagraph01
        _rawParagraph02
        page_title
      }
    }
    siteSettings: allSanitySiteSettings {
      nodes {
        logo {
          asset {
            extension
            gatsbyImageData(width: 300, layout: CONSTRAINED)
            url
          }
        }
        site_hover_color {
          rgb {
            r
            g
            b
            a
          }
        }
        cursor_sparkle
        cursor_static
        cursor_static_color {
          hex
        }
      }
    }
}
`